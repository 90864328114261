define("ember-bootstrap/templates/components/bs-tooltip/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DXVyWDJQ",
    "block": "[[[8,[39,0],[[17,1]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@id\",\"@class\"],[[30,0,[\"ariaRole\"]],[30,0,[\"placement\"]],[30,0,[\"renderInPlace\"]],[30,0,[\"popperTarget\"]],[30,0,[\"popperModifiers\"]],[30,2],[30,0,[\"updatePlacement\"]],[30,0,[\"updatePlacement\"]],[30,3],[29,[\"tooltip \",[30,4],\" \",[52,[30,0,[\"fade\"]],\"fade\"],\" \",[28,[37,2],[\"bs-tooltip-\",[30,0,[\"actualPlacement\"]]],null],\" \",[27],\" \",[52,[30,0,[\"showHelp\"]],\"show\"],\" \",[27],\" \",[27]]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"arrowClass\"]]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"tooltip-inner\"],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@destinationElement\",\"@id\",\"@class\",\"&default\"],false,[\"ember-popper\",\"if\",\"concat\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-tooltip/element.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});