define("ember-date-components/helpers/date-picker-day-classes", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@ember/component/helper"], function (_exports, _slicedToArray2, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.datePickerDayClasses = datePickerDayClasses;
  _exports.default = void 0;

  function datePickerDayClasses(_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 1),
        baseClass = _ref3[0];

    var _ref2$isToday = _ref2.isToday,
        isToday = _ref2$isToday === void 0 ? false : _ref2$isToday,
        _ref2$isSelected = _ref2.isSelected,
        isSelected = _ref2$isSelected === void 0 ? false : _ref2$isSelected,
        _ref2$isDisabled = _ref2.isDisabled,
        isDisabled = _ref2$isDisabled === void 0 ? false : _ref2$isDisabled,
        _ref2$isInRange = _ref2.isInRange,
        isInRange = _ref2$isInRange === void 0 ? false : _ref2$isInRange;
    var isTodayClass = isToday ? " ".concat(baseClass, "--today") : '';
    var isSelectedClass = isSelected ? " ".concat(baseClass, "--selected") : '';
    var isDisabledClass = isDisabled ? " ".concat(baseClass, "--disabled") : '';
    var isInRangeClass = isInRange ? " ".concat(baseClass, "--in-range") : '';
    return "".concat(baseClass).concat(isTodayClass).concat(isSelectedClass).concat(isDisabledClass).concat(isInRangeClass);
  }

  var _default = _helper.default.helper(datePickerDayClasses);

  _exports.default = _default;
});