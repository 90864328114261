define("ember-bootstrap/templates/components/bs-form/element/layout/horizontal/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nnQYtJGO",
    "block": "[[[10,0],[15,0,[29,[[30,0,[\"horizontalInputGridClass\"]],\" \",[30,0,[\"horizontalInputOffsetGridClass\"]]]]],[12],[1,\"\\n\"],[1,\"    \"],[10,0],[14,0,\"form-check\"],[12],[1,\"\\n      \"],[18,4,null],[1,\"\\n      \"],[46,[30,1],null,null,null],[1,\"\\n      \"],[46,[30,2],null,null,null],[1,\"\\n      \"],[46,[30,3],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"yield\",\"component\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/horizontal/checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});