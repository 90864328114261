define("ember-date-components/helpers/is-equal-day", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@ember/component/helper", "@ember/utils", "moment", "@ember/array"], function (_exports, _slicedToArray2, _helper, _utils, _moment, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqualDay = isEqualDay;

  function isEqualDay(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        d1 = _ref2[0],
        d2 = _ref2[1];

    if ((0, _utils.typeOf)(d2) === 'array') {
      return !!(0, _array.A)(d2).find(function (d2) {
        if (!_moment.default.isMoment(d1) || !_moment.default.isMoment(d2)) {
          return false;
        }

        return d1.format('YYYY-MM-DD') === d2.format('YYYY-MM-DD');
      });
    }

    if (!_moment.default.isMoment(d1) || !_moment.default.isMoment(d2)) {
      return false;
    }

    return d1.format('YYYY-MM-DD') === d2.format('YYYY-MM-DD');
  }

  var _default = _helper.default.helper(isEqualDay);

  _exports.default = _default;
});