define("ember-power-calendar/templates/components/power-calendar/nav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+4H5Y13r",
    "block": "[[[10,\"nav\"],[14,0,\"ember-power-calendar-nav\"],[12],[1,\"\\n\"],[41,[30,1,[\"actions\",\"changeCenter\"]],[[[1,\"    \"],[11,\"button\"],[24,0,\"ember-power-calendar-nav-control ember-power-calendar-nav-control--previous\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,1,[\"actions\",\"moveCenter\"]],-1,[30,0,[\"unit\"]],[30,1]],null]],null],[12],[1,\"«\"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"ember-power-calendar-nav-title\"],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[18,2,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,5],[[30,1,[\"center\"]],[30,0,[\"format\"]]],[[\"locale\"],[[30,1,[\"locale\"]]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,1,[\"actions\",\"changeCenter\"]],[[[1,\"    \"],[11,\"button\"],[24,0,\"ember-power-calendar-nav-control ember-power-calendar-nav-control--next\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,1,[\"actions\",\"moveCenter\"]],1,[30,0,[\"unit\"]],[30,1]],null]],null],[12],[1,\"»\"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@calendar\",\"&default\"],false,[\"if\",\"on\",\"fn\",\"has-block\",\"yield\",\"power-calendar-format-date\"]]",
    "moduleName": "ember-power-calendar/templates/components/power-calendar/nav.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});