define("ember-bootstrap/templates/components/bs-dropdown/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CqFyqpKt",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,0,[\"__disabled\"]]],[16,4,[30,0,[\"buttonType\"]]],[16,\"title\",[30,0,[\"title\"]]],[16,\"aria-expanded\",[30,0,[\"ariaExpanded\"]]],[16,0,[29,[\"btn dropdown-toggle \",[52,[30,0,[\"active\"]],\"active\"],\" \",[52,[30,0,[\"block\"]],\"btn-block\"],\" \",[30,0,[\"sizeClass\"]],\" \",[30,0,[\"typeClass\"]]]]],[17,1],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,1],[\"keydown\",[30,0,[\"handleKeyDown\"]]],null],[4,[38,2],[[30,2],\"toggle\"],null],[4,[38,3],[[30,3],\"toggle\"],null],[12],[1,\"\\n  \"],[41,[30,0,[\"icon\"]],[[[10,\"i\"],[15,0,[30,0,[\"icon\"]]],[12],[13],[1,\" \"]],[]],null],[1,[30,0,[\"text\"]]],[18,4,[[28,[37,5],null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[30,0,[\"isFulfilled\"]],[30,0,[\"isPending\"]],[30,0,[\"isRejected\"]],[30,0,[\"isSettled\"]]]]]]],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@registerChildElement\",\"@unregisterChildElement\",\"&default\"],false,[\"if\",\"on\",\"did-insert\",\"will-destroy\",\"yield\",\"hash\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-dropdown/button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});