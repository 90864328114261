define("ember-bootstrap/templates/components/bs-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SOzXL54W",
    "block": "[[[1,[28,[35,0],[[33,1]],null]],[1,\"\\n\"],[41,[30,0,[\"inDom\"]],[[[44,[[50,[30,0,[\"elementComponent\"]],0,null,null]],[[[1,\"    \"],[8,[30,1],[[17,2]],[[\"@placement\",\"@fade\",\"@showHelp\",\"@renderInPlace\",\"@destinationElement\",\"@popperTarget\",\"@autoPlacement\",\"@viewportElement\",\"@viewportPadding\",\"@id\",\"@class\"],[[30,0,[\"placement\"]],[30,0,[\"fade\"]],[30,0,[\"showHelp\"]],[30,0,[\"_renderInPlace\"]],[30,0,[\"destinationElement\"]],[30,0,[\"triggerTargetElement\"]],[30,0,[\"autoPlacement\"]],[30,0,[\"viewportElement\"]],[30,0,[\"viewportPadding\"]],[30,0,[\"overlayId\"]],[30,3]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"        \"],[18,5,[[28,[37,7],null,[[\"close\"],[[30,0,[\"close\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,4]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[1]]]],[]],null]],[\"Element\",\"&attrs\",\"@class\",\"@title\",\"&default\"],false,[\"unbound\",\"_parentFinder\",\"if\",\"let\",\"component\",\"has-block\",\"yield\",\"hash\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-tooltip.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});