define("ember-bootstrap/templates/components/bs-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f7jy2Aj+",
    "block": "[[[11,0],[24,0,\"progress\"],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"bar\"],[[50,[30,0,[\"progressBarComponent\"]],0,null,null]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-progress.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});