define("ember-date-components/helpers/is-equal-time", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@ember/component/helper", "moment"], function (_exports, _slicedToArray2, _helper, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqualTime = isEqualTime;

  function isEqualTime(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        d1 = _ref2[0],
        d2 = _ref2[1];

    if (!_moment.default.isMoment(d1) || !_moment.default.isMoment(d2)) {
      return false;
    }

    return d1.format('HH:mm') === d2.format('HH:mm');
  }

  var _default = _helper.default.helper(isEqualTime);

  _exports.default = _default;
});