define("ember-bootstrap/templates/components/bs-modal-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pnEhJVRq",
    "block": "[[[8,[39,0],[[17,1]],[[\"@open\",\"@fade\",\"@backdrop\",\"@keyboard\",\"@position\",\"@scrollable\",\"@size\",\"@backdropClose\",\"@renderInPlace\",\"@transitionDuration\",\"@backdropTransitionDuration\",\"@onSubmit\",\"@onHide\",\"@onHidden\",\"@onShow\",\"@onShown\"],[[30,0,[\"open\"]],[30,0,[\"fade\"]],[30,0,[\"backdrop\"]],[30,0,[\"keyboard\"]],[30,0,[\"position\"]],[30,0,[\"scrollable\"]],[30,0,[\"size\"]],[30,0,[\"backdropClose\"]],[30,0,[\"renderInPlace\"]],[30,0,[\"transitionDuration\"]],[30,0,[\"backdropTransitionDuration\"]],[30,0,[\"onSubmit\"]],[30,0,[\"onHide\"]],[30,0,[\"onHidden\"]],[30,0,[\"onShow\"]],[30,0,[\"onShown\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"header\"]],null,[[\"@title\",\"@closeButton\"],[[30,3],[30,0,[\"closeButton\"]]]],null],[1,\"\\n  \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[18,5,[[28,[37,2],null,[[\"close\",\"submit\"],[[30,2,[\"close\"]],[30,2,[\"submit\"]]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"footer\"]],null,[[\"@closeTitle\",\"@submitTitle\",\"@submitButtonType\"],[[30,0,[\"closeTitle\"]],[30,4],[30,0,[\"submitButtonType\"]]]],null],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"&attrs\",\"modal\",\"@title\",\"@submitTitle\",\"&default\"],false,[\"bs-modal\",\"yield\",\"hash\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});