define("ember-bootstrap/templates/components/bs-accordion/item/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JdzuDUYc",
    "block": "[[[11,0],[16,0,[29,[[52,[30,0,[\"collapsed\"]],\"collapsed\",\"expanded\"],\" \",\"card-header\",\" \",[27]]]],[24,\"role\",\"tab\"],[17,1],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n\"],[1,\"    \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[1,\"\\n      \"],[10,\"button\"],[15,0,[29,[\"btn btn-link \",[52,[30,2],\"disabled\"]]]],[15,\"disabled\",[30,2]],[14,4,\"button\"],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@disabled\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-accordion/item/title.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});