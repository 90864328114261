define("ember-bootstrap/templates/components/bs-accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bwJWIjNn",
    "block": "[[[11,0],[16,0,[29,[\"accordion\",\" \",[27]]]],[24,\"role\",\"tablist\"],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"item\",\"change\"],[[50,[30,0,[\"itemComponent\"]],0,null,[[\"selected\",\"onClick\"],[[30,0,[\"isSelected\"]],[30,0,[\"doChange\"]]]]],[30,0,[\"doChange\"]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-accordion.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});