define("ember-bootstrap/templates/components/bs-form/element/help-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rp+Nn5qe",
    "block": "[[[11,0],[16,1,[30,1]],[24,0,\"form-text\"],[17,2],[12],[1,\"\\n  \"],[1,[30,3]],[1,\"\\n\"],[13]],[\"@id\",\"&attrs\",\"@text\"],false,[]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/help-text.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});