define("ember-bootstrap/utils/cp/overrideable", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@ember/object", "@ember/debug"], function (_exports, _toConsumableArray2, _object, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = overrideableCP;

  /**
   * CP macro that created a regular computed property, which can be manually overriden.
   * This is needed after implicitly overrideable CPs have been deprecated:
   * https://deprecations-app-prod.herokuapp.com/deprecations/v3.x/#toc_computed-property-override
   *
   * @private
   */
  function overrideableCP() {
    var fn = Array.prototype.slice.call(arguments, -1)[0];
    var args = Array.prototype.slice.call(arguments, 0, arguments.length - 1);
    (true && !(typeof fn === 'function') && (0, _debug.assert)('Last argument for overrideableCP must be a function', typeof fn === 'function'));
    return _object.computed.apply(void 0, (0, _toConsumableArray2.default)(args).concat([{
      get: function get(key) {
        var overridden = this["__".concat(key)];
        return overridden || fn.call(this);
      },
      set: function set(key, value) {
        this["__".concat(key)] = value;
        return value;
      }
    }]));
  }
});