define("ember-bootstrap/templates/components/bs-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kRLx7jiT",
    "block": "[[[44,[[28,[37,1],null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[50,[30,0,[\"toggleComponent\"]],0,null,[[\"onClick\",\"collapsed\"],[[30,0,[\"toggleNavbar\"]],[30,0,[\"_collapsed\"]]]]],[50,[30,0,[\"contentComponent\"]],0,null,[[\"collapsed\",\"onHidden\",\"onShown\"],[[30,0,[\"_collapsed\"]],[30,0,[\"onCollapsed\"]],[30,0,[\"onExpanded\"]]]]],[50,[30,0,[\"navComponent\"]],0,null,[[\"linkToComponent\"],[[50,[30,0,[\"linkToComponent\"]],0,null,[[\"onCollapse\"],[[30,0,[\"collapse\"]]]]]]]],[30,0,[\"collapse\"]],[30,0,[\"expand\"]],[30,0,[\"toggleNavbar\"]]]]]],[[[1,\"    \"],[11,\"nav\"],[16,0,[29,[\"navbar \",[30,0,[\"positionClass\"]],\" \",[30,0,[\"typeClass\"]],\" \",[30,0,[\"breakpointClass\"]],\" \",[30,0,[\"backgroundClass\"]]]]],[17,2],[12],[1,\"\\n\"],[41,[30,0,[\"fluid\"]],[[[1,\"        \"],[18,3,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n          \"],[18,3,[[30,1]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[1]]]],[\"yieldedHash\",\"&attrs\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-navbar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});