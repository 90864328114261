define("ember-bootstrap/templates/components/bs-popover/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xvd1y3Hf",
    "block": "[[[8,[39,0],[[17,1]],[[\"@ariaRole\",\"@placement\",\"@renderInPlace\",\"@popperTarget\",\"@modifiers\",\"@popperContainer\",\"@onCreate\",\"@onUpdate\",\"@id\",\"@class\"],[[30,0,[\"ariaRole\"]],[30,0,[\"placement\"]],[30,0,[\"renderInPlace\"]],[30,0,[\"popperTarget\"]],[30,0,[\"popperModifiers\"]],[30,2],[30,0,[\"updatePlacement\"]],[30,0,[\"updatePlacement\"]],[30,3],[29,[\"popover \",[30,4],\" \",[52,[30,0,[\"fade\"]],\"fade\"],\" \",[28,[37,2],[\"bs-popover-\",[30,0,[\"actualPlacement\"]]],null],\" \",[27],\" \",[52,[30,0,[\"showHelp\"]],\"show\"],\" \",[27],\" \",[27]]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"arrowClass\"]]],[12],[13],[1,\"\\n\"],[41,[30,0,[\"hasTitle\"]],[[[1,\"    \"],[10,\"h3\"],[15,0,[29,[\"popover-header\",[27]]]],[12],[1,[30,5]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[15,0,[29,[\"popover-body\",[27]]]],[12],[18,6,null],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@destinationElement\",\"@id\",\"@class\",\"@title\",\"&default\"],false,[\"ember-popper\",\"if\",\"concat\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-popover/element.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});