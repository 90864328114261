define("ember-bootstrap/templates/components/bs-accordion/item/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uKjskcLA",
    "block": "[[[8,[39,0],[[16,0,[27]],[24,\"role\",\"tabpanel\"]],[[\"@collapsed\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[29,[\"card-body\",\" \",[27],\" \",[30,2]]]],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@collapsed\",\"@class\",\"&default\"],false,[\"bs-collapse\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-accordion/item/body.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});