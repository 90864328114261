define("ember-power-calendar/templates/components/power-calendar/days", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "u5lo88+n",
    "block": "[[[11,0],[24,0,\"ember-power-calendar-days\"],[17,1],[16,\"data-power-calendar-id\",[28,[37,0],[[30,2,[\"calendarUniqueId\"]],[30,2,[\"uniqueId\"]]],null]],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"ember-power-calendar-row ember-power-calendar-weekdays\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"weekdaysNames\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"ember-power-calendar-weekday\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[3]],null],[1,\"  \"],[13],[1,\"\\n\"],[1,\"  \"],[11,0],[24,0,\"ember-power-calendar-day-grid\"],[4,[38,1],[\"keydown\",[30,0,[\"handleKeyDown\"]]],null],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"weeks\"]]],null]],null],\"id\",[[[1,\"      \"],[10,0],[14,0,\"ember-power-calendar-row ember-power-calendar-week\"],[15,\"data-missing-days\",[30,4,[\"missingDays\"]]],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,4,[\"days\"]]],null]],null],\"id\",[[[1,\"          \"],[11,\"button\"],[16,\"data-date\",[29,[[30,5,[\"id\"]]]]],[16,0,[28,[37,4],[[30,5],[30,2],[30,0,[\"weeks\"]],[30,0,[\"dayClass\"]]],null]],[16,\"disabled\",[30,5,[\"isDisabled\"]]],[24,4,\"button\"],[4,[38,1],[\"focus\",[30,0,[\"handleDayFocus\"]]],null],[4,[38,1],[\"blur\",[30,0,[\"handleDayBlur\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"              \"],[18,6,[[30,5],[30,2],[30,0,[\"weeks\"]]]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[30,5,[\"number\"]]],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n\"]],[5]],null],[1,\"      \"],[13],[1,\"\\n\"]],[4]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@calendar\",\"wdn\",\"week\",\"day\",\"&default\"],false,[\"or\",\"on\",\"each\",\"-track-array\",\"ember-power-calendar-day-classes\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-power-calendar/templates/components/power-calendar/days.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});