define("ember-bootstrap/templates/components/bs-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dl4rXY1X",
    "block": "[[[11,0],[16,0,[29,[[52,[30,0,[\"alert\"]],\"alert\"],\" \",[52,[30,0,[\"fade\"]],\"fade\"],\" \",[52,[30,0,[\"dismissible\"]],\"alert-dismissible\"],\" \",[30,0,[\"typeClass\"]],\" \",[52,[30,0,[\"showAlert\"]],\"show\"]]]],[17,1],[12],[1,\"\\n\"],[41,[51,[30,0,[\"hidden\"]]],[[[41,[30,0,[\"dismissible\"]],[[[1,\"      \"],[11,\"button\"],[24,0,\"close\"],[24,\"aria-label\",\"Close\"],[24,4,\"button\"],[4,[38,2],[[30,0],[30,0,[\"dismiss\"]]],null],[12],[1,\"\\n        \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"×\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"unless\",\"action\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-alert.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});