define("ember-date-components/helpers/is-equal-year", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@ember/component/helper", "moment"], function (_exports, _slicedToArray2, _helper, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqualYear = isEqualYear;

  function isEqualYear(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        y1 = _ref2[0],
        y2 = _ref2[1];

    if (!_moment.default.isMoment(y1) || !_moment.default.isMoment(y2)) {
      return false;
    }

    return y1.format('YYYY') === y2.format('YYYY');
  }

  var _default = _helper.default.helper(isEqualYear);

  _exports.default = _default;
});