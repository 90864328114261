define("ember-bootstrap/templates/components/bs-progress/bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ourUuBwU",
    "block": "[[[11,0],[24,\"role\",\"progressbar\"],[16,\"aria-valuenow\",[30,0,[\"value\"]]],[16,\"aria-valuemin\",[30,0,[\"minValue\"]]],[16,\"aria-valuemax\",[30,0,[\"maxValue\"]]],[16,0,[29,[\"progress-bar \",[52,[30,0,[\"striped\"]],\"progress-bar-striped\"],\" \",[30,0,[\"typeClass\"]],\" \",[52,[30,0,[\"animate\"]],\"progress-bar-animated\"]]]],[17,1],[4,[38,1],null,[[\"width\"],[[30,0,[\"percentStyleValue\"]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"showLabel\"]],[[[41,[48,[30,2]],[[[1,\"      \"],[18,2,[[30,0,[\"percentRounded\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"percentRounded\"]]],[1,\"%\\n\"]],[]]]],[]],[[[41,[48,[30,2]],[[[1,\"      \"],[10,1],[14,0,\"sr-only\"],[12],[18,2,[[30,0,[\"percentRounded\"]]]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[14,0,\"sr-only\"],[12],[1,[30,0,[\"percentRounded\"]]],[1,\"%\"],[13],[1,\"\\n\"]],[]]]],[]]],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"style\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-progress/bar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});