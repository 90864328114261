define("ember-bootstrap/utils/dom", ["exports", "@ember/application", "require", "@ember/debug"], function (_exports, _application, _require, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findElementById = findElementById;
  _exports.getDOM = getDOM;
  _exports.getDestinationElement = getDestinationElement;

  /*
   * Implement some helpers methods for interacting with the DOM,
   * be it Fastboot's SimpleDOM or the browser's version.
   *
   * Credit to https://github.com/yapplabs/ember-wormhole, from where this has been shamelessly stolen.
   */
  function childNodesOfElement(element) {
    var children = [];
    var child = element.firstChild;

    while (child) {
      children.push(child);
      child = child.nextSibling;
    }

    return children;
  }

  function findElementById(doc, id) {
    if (doc.getElementById) {
      return doc.getElementById(id);
    }

    var nodes = childNodesOfElement(doc);
    var node;

    while (nodes.length) {
      node = nodes.shift();

      if (node.getAttribute && node.getAttribute('id') === id) {
        return node;
      }

      nodes = childNodesOfElement(node).concat(nodes);
    }
  } // Private Ember API usage. Get the dom implementation used by the current
  // renderer, be it native browser DOM or Fastboot SimpleDOM


  function getDOM(context) {
    var renderer = context.renderer;

    if (!renderer._dom) {
      // pre glimmer2
      var container = _application.getOwner ? (0, _application.getOwner)(context) : context.container;
      var documentService = container.lookup('service:-document');

      if (documentService) {
        return documentService;
      }

      renderer = container.lookup('renderer:-dom');
    }

    if (renderer._dom && renderer._dom.document) {
      return renderer._dom.document;
    } else {
      throw new Error('Could not get DOM');
    }
  }

  function getDestinationElement(context) {
    var dom = getDOM(context);
    var destinationElement = findElementById(dom, 'ember-bootstrap-wormhole');

    if (true
    /* DEBUG */
    && !destinationElement) {
      var config = (0, _application.getOwner)(context).resolveRegistration('config:environment');

      if (config.environment === 'test' && typeof FastBoot === 'undefined') {
        var id;

        if (_require.default.has('@ember/test-helpers/dom/get-root-element')) {
          try {
            id = (0, _require.default)('@ember/test-helpers/dom/get-root-element').default().id;
          } catch (ex) {// no op
          }
        }

        if (!id) {
          return document.querySelector('#ember-testing > .ember-view');
        }

        return document.getElementById(id);
      }

      (true && (0, _debug.warn)("No wormhole destination element found for component ".concat(context, ". If you have set `insertEmberWormholeElementToDom` to false, you should insert a `div#ember-bootstrap-wormhole` manually!"), false, {
        id: 'ember-bootstrap.no-destination-element'
      }));
    }

    return destinationElement;
  }
});