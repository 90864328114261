define("ember-bootstrap/templates/components/bs-form/element/legend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wMLisd7n",
    "block": "[[[10,\"legend\"],[15,0,[29,[[52,[30,0,[\"invisibleLabel\"]],\"sr-only\"],\" \",[30,0,[\"labelClass\"]],\" \",[52,[30,0,[\"isHorizontalAndNotCheckbox\"]],\"col-form-label\"],\" \",[30,0,[\"sizeClass\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[30,1]],[1,\"\\n\"],[13]],[\"@label\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/legend.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});