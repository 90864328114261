define("ember-bootstrap/templates/components/bs-navbar/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nA6xKxy8",
    "block": "[[[8,[39,0],[[24,0,\"navbar-collapse\"],[17,1]],[[\"@collapsed\",\"@onHidden\",\"@onShown\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[18,5,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@collapsed\",\"@onHidden\",\"@onShown\",\"&default\"],false,[\"bs-collapse\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-navbar/content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});