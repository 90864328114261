define("ember-bootstrap/templates/components/bs-form/element/control/textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oFTwckfW",
    "block": "[[[11,\"textarea\"],[16,1,[30,1]],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,\"readonly\",[30,0,[\"readonly\"]]],[16,\"aria-describedby\",[30,0,[\"ariaDescribedBy\"]]],[16,2,[30,0,[\"value\"]]],[16,0,[29,[\"form-control \",[30,0,[\"formValidationClass\"]]]]],[17,2],[4,[38,0],[\"change\",[30,0,[\"handleChange\"]]],null],[4,[38,0],[\"input\",[30,0,[\"handleInput\"]]],null],[12],[1,\"\"],[13]],[\"@id\",\"&attrs\"],false,[\"on\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/control/textarea.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});