define("ember-bootstrap/utils/default-decorator", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@ember/object"], function (_exports, _objectSpread2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultValue;

  function defaultValue(target, key, descriptor) {
    var initializer = descriptor.initializer,
        value = descriptor.value;
    return (0, _object.computed)({
      get: function get() {
        return initializer ? initializer.call(this) : value;
      },
      set: function set(_, v) {
        return v;
      }
    })(target, key, (0, _objectSpread2.default)((0, _objectSpread2.default)({}, descriptor), {}, {
      value: undefined,
      initializer: undefined
    }));
  }
});