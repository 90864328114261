define("ember-bootstrap/templates/components/bs-form/element/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/kAM8qO3",
    "block": "[[[41,[30,0,[\"show\"]],[[[41,[30,0,[\"showMultipleErrors\"]],[[[1,\"    \"],[10,0],[14,0,\"pre-scrollable\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"invalid-feedback d-block\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"invalid-feedback d-block\"],[12],[1,[30,1,[\"firstObject\"]]],[13],[1,\"\\n\"]],[]]]],[]],null]],[\"@messages\",\"message\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/errors.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});