define("ember-bootstrap/templates/components/bs-modal/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rKntkOvh",
    "block": "[[[11,0],[24,\"role\",\"dialog\"],[24,\"tabindex\",\"-1\"],[16,\"aria-labelledby\",[30,0,[\"ariaLabelledby\"]]],[16,0,[29,[\"modal \",[52,[30,1],\"fade\"],\" \",[52,[30,2],\"show\"],\" \",[52,[30,3],\"d-block\"]]]],[17,4],[4,[38,1],[\"keydown\",[30,0,[\"handleKeyDown\"]]],null],[4,[38,1],[\"mousedown\",[30,0,[\"handleMouseDown\"]]],null],[4,[38,1],[\"mouseup\",[30,0,[\"handleMouseUp\"]]],null],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,2],null,[[\"paddingLeft\",\"paddingRight\",\"display\"],[[28,[37,3],[[30,5],\"px\"],null],[28,[37,3],[[30,6],\"px\"],null],[52,[30,3],\"block\",\"\"]]]],[4,[38,4],[[30,0],\"_element\"],null],[4,[38,5],[[30,0,[\"getOrSetTitleId\"]]],null],[4,[38,5],[[30,0,[\"setInitialFocus\"]]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"modal-dialog \",[30,0,[\"sizeClass\"]],\" \",[52,[30,7],\"modal-dialog-centered\"],\" \",[52,[30,8],\"modal-dialog-scrollable\"]]]],[14,\"role\",\"document\"],[12],[1,\"\\n    \"],[11,0],[24,0,\"modal-content\"],[4,[38,6],null,[[\"shouldSelfFocus\",\"focusTrapOptions\"],[true,[28,[37,7],null,[[\"clickOutsideDeactivates\"],[true]]]]]],[12],[1,\"\\n      \"],[18,9,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@fade\",\"@showModal\",\"@inDom\",\"&attrs\",\"@paddingLeft\",\"@paddingRight\",\"@centered\",\"@scrollable\",\"&default\"],false,[\"if\",\"on\",\"style\",\"concat\",\"ref\",\"did-insert\",\"focus-trap\",\"hash\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-modal/dialog.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});