define("ember-bootstrap/templates/components/bs-form/element/control/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oZ+AGqxr",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[44,[[28,[37,3],[[30,4],\"-\",[30,3]],null]],[[[1,\"      \"],[10,0],[15,0,[29,[\"form-check\",[52,[30,0,[\"inline\"]],\" form-check-inline\"]]]],[12],[1,\"\\n        \"],[11,\"input\"],[24,4,\"radio\"],[24,0,\"form-check-input\"],[16,1,[30,5]],[16,\"checked\",[28,[37,5],[[30,2],[30,6]],null]],[16,\"onclick\",[28,[37,6],[[30,0],[30,0,[\"onChange\"]],[30,2]],null]],[16,3,[30,7]],[16,\"required\",[30,8]],[16,\"disabled\",[30,9]],[16,\"autofocus\",[30,10]],[16,\"tabindex\",[30,11]],[16,\"form\",[30,12]],[16,\"title\",[30,13]],[17,14],[12],[13],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[30,5]],[14,0,\"form-check-label\"],[12],[1,\"\\n\"],[41,[48,[30,16]],[[[1,\"            \"],[18,16,[[30,2],[30,3]]],[1,\"\\n\"]],[]],[[[41,[30,15],[[[1,\"              \"],[1,[28,[35,9],[[30,2],[30,15]],null]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[30,2]],[1,\"\\n\"]],[]]]],[]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[5]]]],[2,3]],null]],[\"@options\",\"option\",\"index\",\"@id\",\"id\",\"@value\",\"@name\",\"@required\",\"@disabled\",\"@autofocus\",\"@tabindex\",\"@form\",\"@title\",\"&attrs\",\"@optionLabelPath\",\"&default\"],false,[\"each\",\"-track-array\",\"let\",\"concat\",\"if\",\"bs-eq\",\"action\",\"has-block\",\"yield\",\"get\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/control/radio.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});