define("ember-table/components/-private/row-wrapper", ["exports", "@ember/template-factory", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/array", "ember-table/-private/utils/ember", "ember-table/-private/utils/array", "ember-table/-private/utils/observer"], function (_exports, _templateFactory, _component, _object, _computed, _array, _ember, _array2, _observer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CellWrapper = _object.default.extend({
    /* eslint-disable-next-line ember/no-observers, ember-best-practices/no-observers */
    columnValueValuePathDidChange: (0, _observer.observer)('columnValue.valuePath', function () {
      var columnValuePath = (0, _object.get)(this, 'columnValue.valuePath');
      var cellValue = columnValuePath ? (0, _computed.alias)("rowValue.".concat(columnValuePath)) : null;
      (0, _object.defineProperty)(this, 'cellValue', cellValue);
      (0, _ember.notifyPropertyChange)(this, 'cellValue');
    }),
    cellMeta: (0, _object.computed)('rowMeta', 'columnValue', function () {
      var rowMeta = (0, _object.get)(this, 'rowMeta');
      var columnValue = (0, _object.get)(this, 'columnValue');

      if (!rowMeta._cellMetaCache.has(columnValue)) {
        rowMeta._cellMetaCache.set(columnValue, _object.default.create());
      }

      return rowMeta._cellMetaCache.get(columnValue);
    })
  });

  var layout = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield this.api}}
  */
  {
    "id": "pY/iJbm2",
    "block": "[[[18,1,[[30,0,[\"api\"]]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "/opt/atlassian/pipelines/agent/build/ember-table/components/-private/row-wrapper.js",
    "isStrictMode": false
  });

  var _default = _component.default.extend({
    layout: layout,
    tagName: '',
    canSelect: undefined,
    checkboxSelectionMode: undefined,
    columnMetaCache: undefined,
    columns: undefined,
    rowMetaCache: undefined,
    rowSelectionMode: undefined,
    rowToggleMode: undefined,
    rowValue: undefined,
    rowsCount: undefined,
    init: function init() {
      this._super.apply(this, arguments);

      this._cells = (0, _array.A)([]);
    },
    destroy: function destroy() {
      this._cells.forEach(function (cell) {
        return cell.destroy();
      });

      this._super.apply(this, arguments);
    },
    api: (0, _object.computed)('rowValue', 'rowMeta', 'cells', 'canSelect', 'rowSelectionMode', 'rowToggleMode', 'rowsCount', function () {
      var rowValue = this.get('rowValue');
      var rowMeta = this.get('rowMeta');
      var cells = this.get('cells');
      var canSelect = this.get('canSelect');
      var rowSelectionMode = canSelect ? this.get('rowSelectionMode') : 'none';
      var rowToggleMode = this.get('rowToggleMode');
      var rowsCount = this.get('rowsCount');
      return {
        rowValue: rowValue,
        rowMeta: rowMeta,
        cells: cells,
        rowSelectionMode: rowSelectionMode,
        rowToggleMode: rowToggleMode,
        rowsCount: rowsCount
      };
    }),
    rowMeta: (0, _object.computed)('rowValue', function () {
      var rowValue = this.get('rowValue');
      var rowMetaCache = this.get('rowMetaCache');
      return rowMetaCache.get(rowValue);
    }),
    cells: (0, _object.computed)('rowValue', 'rowMeta', 'columns.[]', 'canSelect', 'checkboxSelectionMode', 'rowSelectionMode', function () {
      var _this = this;

      var columns = this.get('columns');
      var numColumns = (0, _object.get)(columns, 'length');
      var rowValue = this.get('rowValue');
      var rowMeta = this.get('rowMeta');
      var rowsCount = this.get('rowsCount');
      var canSelect = this.get('canSelect');
      var checkboxSelectionMode = canSelect ? this.get('checkboxSelectionMode') : 'none';
      var rowSelectionMode = canSelect ? this.get('rowSelectionMode') : 'none';
      var _cells = this._cells;

      if (numColumns !== _cells.length) {
        while (_cells.length < numColumns) {
          _cells.pushObject(CellWrapper.create());
        }

        while (_cells.length > numColumns) {
          _cells.popObject().destroy();
        }
      }

      _cells.forEach(function (cell, i) {
        var columnValue = (0, _array2.objectAt)(columns, i);

        var columnMeta = _this.get('columnMetaCache').get(columnValue); // eslint-disable-next-line ember/no-side-effects, ember-best-practices/no-side-effect-cp


        (0, _object.setProperties)(cell, {
          checkboxSelectionMode: checkboxSelectionMode,
          columnMeta: columnMeta,
          columnValue: columnValue,
          rowMeta: rowMeta,
          rowSelectionMode: rowSelectionMode,
          rowValue: rowValue,
          rowsCount: rowsCount
        });
      });

      return _cells;
    })
  });

  _exports.default = _default;
});