define("ember-popper/templates/components/ember-popper-targeting-parent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C7CDDInh",
    "block": "[[[1,[28,[35,0],[[30,0,[\"_parentFinder\"]]],null]],[1,\"\\n\\n\"],[41,[30,0,[\"renderInPlace\"]],[[[1,\"  \"],[11,0],[16,1,[30,1]],[16,0,[30,2]],[16,\"hidden\",[30,0,[\"hidden\"]]],[16,\"role\",[30,3]],[17,4],[4,[38,2],[[30,0],\"_popperElement\"],null],[12],[1,\"\\n    \"],[18,5,[[28,[37,4],null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[28,[37,5],[[30,0],\"disableEventListeners\"],null],[28,[37,5],[[30,0],\"enableEventListeners\"],null],[28,[37,5],[[30,0],\"scheduleUpdate\"],null],[28,[37,5],[[30,0],\"update\"],null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[40,[[[1,\"    \"],[11,0],[16,1,[30,1]],[16,0,[30,2]],[16,\"hidden\",[30,0,[\"hidden\"]]],[16,\"role\",[30,3]],[17,4],[4,[38,2],[[30,0],\"_popperElement\"],null],[12],[1,\"\\n      \"],[18,5,[[28,[37,4],null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[28,[37,5],[[30,0],\"disableEventListeners\"],null],[28,[37,5],[[30,0],\"enableEventListeners\"],null],[28,[37,5],[[30,0],\"scheduleUpdate\"],null],[28,[37,5],[[30,0],\"update\"],null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,7],[[30,0,[\"_popperContainer\"]]],null],null]],[]]]],[\"@id\",\"@class\",\"@ariaRole\",\"&attrs\",\"&default\"],false,[\"unbound\",\"if\",\"ref\",\"yield\",\"hash\",\"action\",\"in-element\",\"-in-el-null\"]]",
    "moduleName": "ember-popper/templates/components/ember-popper-targeting-parent.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});