define("ember-bootstrap/templates/components/bs-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yZLCy3KP",
    "block": "[[[1,[28,[35,0],[[33,1]],null]],[1,\"\\n\"],[41,[30,0,[\"inDom\"]],[[[44,[[50,[30,0,[\"elementComponent\"]],0,null,null]],[[[1,\"    \"],[8,[30,1],[[17,2]],[[\"@placement\",\"@fade\",\"@showHelp\",\"@title\",\"@renderInPlace\",\"@popperTarget\",\"@destinationElement\",\"@autoPlacement\",\"@viewportElement\",\"@viewportPadding\",\"@id\",\"@class\"],[[30,0,[\"placement\"]],[30,0,[\"fade\"]],[30,0,[\"showHelp\"]],[30,3],[30,0,[\"_renderInPlace\"]],[30,0,[\"triggerTargetElement\"]],[30,0,[\"destinationElement\"]],[30,0,[\"autoPlacement\"]],[30,0,[\"viewportElement\"]],[30,0,[\"viewportPadding\"]],[30,0,[\"overlayId\"]],[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[18,5,[[28,[37,6],null,[[\"close\"],[[30,0,[\"close\"]]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]]]],[]],null]],[\"Element\",\"&attrs\",\"@title\",\"@class\",\"&default\"],false,[\"unbound\",\"_parentFinder\",\"if\",\"let\",\"component\",\"yield\",\"hash\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-popover.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});